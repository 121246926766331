<!-- 互动交流  就一个表单 还没加功能-->
<template>
  <div>
    <div class="hr-10"></div>
    <el-card class="box-card centebox">
      <Breadcrumb Otitle="互动交流" />
      <div class="hr-10"></div>
      <el-form
        label-position="right"
        ref="form"
        :model="form"
        :rules="rules"
        class="msgboxs"
        v-if="leix"
      >
        <div v-if="leix === 'admin'" class="admin">
          管理员账号不可留言
          人才（不论是否认证）、单位（不论是否认证）、乡贤账号可留言，每日限制为5条
        </div>
        <div v-else>
          <el-form-item prop="content">
            <el-input
              type="textarea"
              rows="10"
              :maxLength="250"
              v-model="form.content"
              placeholder="可以提出您的疑问或建议，如方便请留下联系方式以便于工作人员给您回电解答！"
            ></el-input>
            <!--          <editor ref="editor"/>-->
            <el-alert
              title="注：请不要超过250字"
              type="error"
              class="errs"
            ></el-alert>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              :disabled="disabledButton"
              @click="onSubmit('form')"
              >立即提交</el-button
            >
            <el-button @click="resetForm('form')">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
      <div v-else class="nologin">
        <el-alert title="还未登录，请先登录" type="warning"> </el-alert>
        <el-button type="primary" class="button" @click="login">
          登录
        </el-button>
      </div>
    </el-card>
    <div class="hr-10"></div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/WebPanel/Breadcrumb.vue";
//import editor from "@/components/editor"
export default {
  components: {
    Breadcrumb,
    // editor
  },
  data() {
    return {
      disabledButton: false,
      leix: "",
      id: "",
      user: "",
      form: {
        content: "",
      },
      rules: {
        content: { required: true, message: "请输入留言信息", trigger: "blur" },
      },
    };
  },
  //用于数据初始化
  created() {
    this.leix = sessionStorage.getItem("Leix");
    this.user = sessionStorage.getItem("user");
    this.id = sessionStorage.getItem("ID");
    this.islesx(this.id);
    //this.leix = 'enterprise'
  },
  methods: {
    onSubmit(formName) {
      this.disabledButton = true;
      setTimeout(() => {
        this.disabledButton = false;
      }, 3000);

      let self = this;
      // this.form.content = self.$refs.editor.myValue
      if (this.form.content.length <= 250) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios
              .post("/feedback", {
                content: this.form.content,
                userId: this.id,
                userType: this.leix,
              })
              .then(function(res) {
                if (res.data.status === 200) {
                  self.form.content = "";
                  // self.$refs.editor.myValue = '';
                  self.$root.success("留言成功");
                } else if (res.data.status === 400) {
                  self.form.content = "";
                  // self.$refs.editor.myValue = '';
                  self.$root.warn(res.data.data);
                }
              });
          } else {
            return false;
          }
        });
      } else {
        self.form.content = "";
        // self.$refs.editor.myValue = '';
        this.$root.warn("请不要超过250字");
      }
    },
    login() {
      this.$router.push("/login");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    sdas() {
      this.$axios.delete("/feedback/batch", {});
    },
    islesx() {
      this.$axios
        .get("/account", {
          params: {
            id: sessionStorage.getItem("userId"),
          },
        })
        .then(function(res) {});
    },
  },
};
</script>

<style lang="less" scoped>
.msgboxs {
  max-width: 1000px;
  margin: 0 auto;

  .errs {
    background: #fff;
    height: 20px;
    padding: 0;
  }
}

.nologin {
  position: relative;
  min-height: 300px;

  .button {
    top: 50%;
    left: 50%;
    position: absolute;

    a {
      color: #fff;
      text-decoration: none;
    }
  }
}
</style>
